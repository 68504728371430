import Image from "next/image";
import React from "react";

const SpecialOfferCard = ({ flight, handleClickCards }) => {
  return (
    <div
      onClick={() => handleClickCards(flight)}
      className="bg-[#D9D9D980] hover:bg-[#D9D9D9] duration-200 relative rounded-[20px] min-w-[290px]  group/edit cursor-pointer"
    >
      <div className="rounded-[20px] overflow-hidden h-[210px] md:h-60 w-full">
        <Image
          alt={flight.route}
          width={700}
          height={700}
          src={flight.image || cardImg}
          className="h-full w-full object-cover group-hover/edit:scale-105 duration-300"
        />
      </div>
      <h3 className="py-2 text-center md:text-base text-sm px-2">
        {flight.route.origin.city} to {flight.route.destination.city} on{" "}
        {new Date(flight.date).toLocaleDateString("en-GB", {
          day: "numeric",
          month: "long",
        })}
      </h3>
      <div className="absolute top-3 md:top-4 right-3 md:right-4 bg-[#009D4D] font-medium text-white rounded-[10px] text-xs lg:text-sm px-2 md:px-4 py-1">
        BDT {flight.price.toLocaleString()}
      </div>
      <div className="absolute top-0 left-0 bg-[#009D4D] text-[#faffff] font-normal rounded-tl-[10px] rounded-br-[10px] text-xs px-3 py-1">
        Hamsing
      </div>
    </div>
  );
};

export default SpecialOfferCard;
