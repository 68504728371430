import(/* webpackMode: "eager" */ "D:\\HAMS DEV\\HAMSFLY-PRODUCTION\\node_modules\\next\\dist\\client\\image-component.js");
;
import(/* webpackMode: "eager" */ "D:\\HAMS DEV\\HAMSFLY-PRODUCTION\\src\\assets\\flightsHome\\biman-bangla.png");
;
import(/* webpackMode: "eager" */ "D:\\HAMS DEV\\HAMSFLY-PRODUCTION\\src\\assets\\flightsHome\\emirates.png");
;
import(/* webpackMode: "eager" */ "D:\\HAMS DEV\\HAMSFLY-PRODUCTION\\src\\assets\\flightsHome\\Networking Manager.png");
;
import(/* webpackMode: "eager" */ "D:\\HAMS DEV\\HAMSFLY-PRODUCTION\\src\\assets\\flightsHome\\Online Money Transfer.png");
;
import(/* webpackMode: "eager" */ "D:\\HAMS DEV\\HAMSFLY-PRODUCTION\\src\\assets\\flightsHome\\qatar-airways.png");
;
import(/* webpackMode: "eager" */ "D:\\HAMS DEV\\HAMSFLY-PRODUCTION\\src\\assets\\flightsHome\\singapore-airlines.png");
;
import(/* webpackMode: "eager" */ "D:\\HAMS DEV\\HAMSFLY-PRODUCTION\\src\\assets\\flightsHome\\usBangla.png");
;
import(/* webpackMode: "eager" */ "D:\\HAMS DEV\\HAMSFLY-PRODUCTION\\src\\assets\\flightsHome\\Vestige.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\HAMS DEV\\HAMSFLY-PRODUCTION\\src\\components\\flightsHome\\banner\\FlightBanner.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\HAMS DEV\\HAMSFLY-PRODUCTION\\src\\components\\flightsHome\\banner\\MobileBanner.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\HAMS DEV\\HAMSFLY-PRODUCTION\\src\\components\\flightsHome\\CheapFlights.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\HAMS DEV\\HAMSFLY-PRODUCTION\\src\\components\\flightsHome\\FlightRating.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\HAMS DEV\\HAMSFLY-PRODUCTION\\src\\components\\flightsHome\\SpecialOffers.jsx");
