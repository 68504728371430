"use client";
import TravelarCounter from "@/components/homeComponent/banner/TravelarCounter";
import Onway from "@/components/homeComponent/banner/OnWay";
import RoundWay from "@/components/homeComponent/banner/Return";
import Multicity from "@/components/homeComponent/banner/Multicity";
import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";

const FlightBanner = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const [type, setType] = useState("oneway");

  useEffect(() => {
    const getValueOfTravelType = localStorage.getItem("type");
    if (getValueOfTravelType) {
      setType(getValueOfTravelType);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the dropdown and button
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleTypeChange = (wayType) => {
    const selectedType = wayType.toLowerCase();
    setType(selectedType);
    localStorage.setItem("type", selectedType);
    setIsDropdownOpen(false);
    const event = new Event("typeChange");
    window.dispatchEvent(event);
  };

  return (
    <div>
      <div className="relative flex flex-col items-center pt-10 justify-center">
        <div className="text-white text-center mt-5 space-y-4">
          <div className="w-full sm:w-2/3 lg:w-auto mx-auto py-6 px-4 sm:px-8 rounded">
            <div className="w-full md:w-[80%] lg:w-full mx-auto">
              <div className="">
                <div className="flex gap-3 mb-2 justify-between">
                  <div className="relative w-full md:w-auto">
                    {/* Toggle Button */}
                    <div
                      className="md:w-32 flex gap-2 bg-green-700/15 text-black text-sm justify-center items-center text-center py-1.5 mb-1 rounded-xl cursor-pointer"
                      ref={buttonRef}
                      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    >
                      <h3 className="capitalize">{type}</h3>
                      {isDropdownOpen ? (
                        <MdOutlineKeyboardArrowUp fontSize={20} />
                      ) : (
                        <MdOutlineKeyboardArrowDown fontSize={20} />
                      )}
                    </div>

                    {/* Traveler Dropdown */}
                    {isDropdownOpen && (
                      <div
                        ref={dropdownRef}
                        className="mx-auto bg-white border text-black rounded-lg overflow-hidden shadow-lg w-full md:w-64 absolute left-0 z-10"
                      >
                        {["Oneway", "Roundway", "Multi City"].map(
                          (way, index, array) => {
                            return (
                              <div key={way}>
                                <div className="flex items-center ">
                                  <button
                                    onClick={() => handleTypeChange(way)}
                                    className={`p-3 text-sm  duration-100 border-b ${
                                      index === array.length - 1
                                        ? "border-b-0"
                                        : ""
                                    } w-full text-left cursor-pointer`}
                                  >
                                    {way}
                                  </button>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    )}
                  </div>
                  <TravelarCounter />
                </div>

                {type === "oneway" && <Onway />}
                {type === "roundway" && <RoundWay />}
                {type === "multi city" && <Multicity />}
              </div>
              {/* <div className="text-left flex items-center gap-2">
                <input type="checkbox" name="" id="" />{" "}
                <span>Check hotels with HAMS</span>
              </div> */}
              {/* <div className="flex mt-5 md:ml-2 gap-1 md:gap-7">
                <div className="flex items-center gap-1 relative cursor-pointer">
                  <input
                    value="nearby airports"
                    // defaultChecked
                    type="radio"
                    name="transport"
                    id="nearby airports"
                  />
                  <p className="text-sm text-white font-medium ">
                    Nearby Airports
                  </p>
                </div>

                <div className="flex items-center gap-1 relative cursor-pointer">
                  <input
                    value="Prefer nonstop"
                    type="radio"
                    name="transport"
                    id="Prefer nonstop"
                  />
                  <p className="text-sm text-white font-medium text-center">
                    Prefer nonstop
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* <LoginMsg /> */}
    </div>
  );
};

export default FlightBanner;
